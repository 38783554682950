import {
  LinkBox,
  IconButton,
  LinkOverlay,
  Text,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';

import { MotionBox } from '@/utils/motion';
import { SkillTypes } from '@/utils/skills';

interface SkillsProps {
  skill: SkillTypes;
}

export function Card({ skill }: SkillsProps): JSX.Element {
  return (
    <MotionBox whileHover={{ y: -5 }}>
      <LinkBox
        as="article"
        w="100%"
        p={4}
        borderColor={useColorModeValue('blue.500', 'purple.300')}
        borderRadius={20}
        borderWidth="1px"
        transition=".5s"
        cursor="pointer"
        display="flex"
        role="group"
        _hover={{
          borderColor: 'green.500',
        }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <IconButton
            as="a"
            aria-label={skill?.name}
            mr={3}
            _groupHover={{ color: 'green.500' }}
            icon={skill?.icon}
          />
          <LinkOverlay href={skill?.url} rel="noopener" isExternal>
            <Flex>
              <Text size="sm" _hover={{ color: 'green.500' }}>
                {skill?.name}
              </Text>
            </Flex>
          </LinkOverlay>
        </Flex>
      </LinkBox>
    </MotionBox>
  );
}
