import { Box, Flex, Image, Text, Heading } from '@chakra-ui/react';

import { MotionBox } from '@/utils/motion';
import { Project } from '@/utils/project';

import { Paragraph } from './Paragraph';

interface ProjectProps {
  project: Project;
}

export function ProjectCard({ project }: ProjectProps): JSX.Element {
  return (
    <MotionBox whileHover={{ y: -5 }}>
      <Box
        p={4}
        display={{ md: 'flex' }}
        borderWidth={1}
        margin={2}
        rounded="10px"
        _hover={{
          borderColor: 'green.500',
        }}
      >
        <Flex
          alignItems="center"
          textAlign={{ base: 'center', md: 'start' }}
          justifyContent="space-around"
          direction={{ base: 'column', md: 'row' }}
        >
          <Image
            margin="auto"
            src={project.imageUrl}
            alt={project.alt}
            objectFit="cover"
            boxSize={['100px', '150px', '150px', '150px']}
          />
          <Flex flexDirection="column" ml={[0, 5, 5]} mt={[5, 5, 0, 0]}>
            <Heading
              as="h2"
              fontSize="lg"
              fontWeight="600"
              bgGradient="linear(to-l, #7928CA, #FF0080)"
              bgClip="text"
            >
              {project.name}
            </Heading>
            <Paragraph textProps={{ mt: '10px' }}>
              <Text>{project.summary}</Text>
            </Paragraph>
          </Flex>
        </Flex>
      </Box>
    </MotionBox>
  );
}
