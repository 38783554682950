/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Container, Divider } from '@chakra-ui/react';

import { ProfileSection } from '@/screen/atoms/Home/Main';
import { Projects } from '@/screen/atoms/Home/Projects';
import { Skills } from '@/screen/atoms/Home/Skills';

export default function Home(): JSX.Element {
  return (
    <Container
      maxW="container.lg"
      mt={{ base: '5', md: '10' }}
      mb={{ base: '5', md: '10' }}
    >
      <ProfileSection />
      <Divider my={7} />
      <Skills />
      <Divider my={7} />
      <Projects />
    </Container>
  );
}
