import { Text, TextProps, useColorModeValue } from '@chakra-ui/react';

interface PragraphProps {
  children: React.ReactNode;
  textProps?: TextProps;
}

export function Paragraph({ children, textProps }: PragraphProps): JSX.Element {
  const textColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Text
      color={textColor}
      {...textProps}
      textAlign={{ base: 'center', md: 'start' }}
    >
      {children}
    </Text>
  );
}
