import {
  Heading,
  SlideFade,
  Text,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

import { ProjectCard } from '@/components/common/ui/ProjectCard';
import { projects } from '@/means/data';

export function Projects(): JSX.Element {
  return (
    <SlideFade in offsetY={80} delay={0.2}>
      <Heading
        as="h1"
        fontSize={{ base: '24px', md: '30px', lg: '36px' }}
        mb={3}
      >
        Projetos
      </Heading>
      <Text textColor={useColorModeValue('gray.600', 'gray.400')} fontSize="lg">
        Casos de sucesso
      </Text>

      <SimpleGrid columns={[1, 2, 2, 2]} mt={5}>
        {projects.map(project => {
          return <ProjectCard key={project.name} project={project} />;
        })}
      </SimpleGrid>
    </SlideFade>
  );
}
