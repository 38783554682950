import {
  Heading,
  SlideFade,
  Grid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Card } from '@/components/common/ui/Card';
import { skill } from '@/means/data';

export function Skills(): JSX.Element {
  return (
    <SlideFade in offsetY={80} delay={0.2}>
      <Heading
        as="h1"
        fontSize={{ base: '24px', md: '30px', lg: '36px' }}
        mb={3}
      >
        Skills
      </Heading>
      <Text textColor={useColorModeValue('gray.600', 'gray.400')} fontSize="lg">
        Uma lista do que eu costumo utilizar no meu dia-a-dia
      </Text>
      <Grid
        mt={5}
        templateColumns={[
          '1fr',
          'repeat(2,1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
        ]}
        gap={[2, 5, 5, 5]}
      >
        {skill.map(skillInfo => (
          <Card skill={skillInfo} key={skillInfo?.name} />
        ))}
      </Grid>
    </SlideFade>
  );
}
