import { SlideFade, Box, Heading, Avatar, Text, Flex } from '@chakra-ui/react';

import { Paragraph } from '@/components/common/ui/Paragraph';

export function ProfileSection(): JSX.Element {
  return (
    <SlideFade in offsetX={80}>
      <Box>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading
            as="h1"
            fontSize={{ base: '2rem', md: '3rem', lg: '4rem' }}
            mb={3}
          >
            <Text fontSize={{ base: '1rem', md: '1.8rem', lg: '2rem' }}>
              Hello World! <span className="waving-hand">👋</span>
            </Text>
            Gabriel Hijazi
          </Heading>

          <Flex alignItems="flex-end">
            <Avatar
              name="Gabriel Hijazi"
              src="https://avatars.githubusercontent.com/u/46979459?v=4"
              mb={5}
              size="xl"
            />
          </Flex>
        </Flex>
        <Paragraph textProps={{ fontSize: '2xl', lineHeight: 1.6 }}>
          Desenvolvedor & Engenheiro de Software de Campo Grande - MS
        </Paragraph>
        <Paragraph
          textProps={{ fontSize: '1xl', lineHeight: 1.6, fontStyle: 'italic' }}
        >
          “In order to consider yourself a professional, you must create clean
          code.” - Uncle Bob
        </Paragraph>
      </Box>
    </SlideFade>
  );
}
